<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.companies") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="companies"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="name"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/companies/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/companies/create')"
              id="header-company-create-button"
              label="create_company"
              textVariant="white"
              type="button"
              variant="indigo"
          /></span>
        </template>
        <template #cell(licenceValidity)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getLocaleDate } from "../utils/dateUtils";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
export default {
  components: { Breadcrumb, CustomTable, FormButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "licenceValidity",
        label: "licence_validity",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
    ]);

    const searchFields = ref(["name"]);

    const sortFields = ref(["name", "licenceValidity"]);

    const companies = computed(() => store.state.company.companies);

    onMounted(() => {
      if (!companies.value.length) {
        store.dispatch("company/getCompanies");
      }
    });

    return {
      companies,
      fields,
      getLocaleDate,
      locale,
      router,
      searchFields,
      sortFields,
      t,
      waiting: computed(() => store.state.company.waiting),
    };
  },
};
</script>
